@charset 'utf-8';

//////////Variables//////////

$font-1: Raleway, sans-serif;
$font-2: "Playfair Display",serif;
$text_color: #333;

$bg_color-1: #FFF;
$bg_color-2: #EDEDED;

$fz_color-1: #85D8CE;
$fz_color-2: #085078;
$fz_color-3: #5AAAB1;

//////////Extend//////////
%animation {
  transition: all 0.4s ease-in-out;
}

%clearfix {
  &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

//////////Selection//////////
*::selection {
  background: $fz_color-3 none repeat scroll 0 0;
  color: #ffffff;
  outline: medium none;
  text-shadow: none;
}

//////////Structure//////////
* {
  box-sizing: border-box;
  font-family: $font-1, sans-serif;
}

html {
  font-size: 8px;
}

@media (min-width: 992px) {
  html {
    font-size: 10px;
  }
}


body {
  background-color: $bg_color-1;
  color: $text_color;
}

.container {
  @extend %clearfix;
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 20px;
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  > div {
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.col-xs-1 {
  flex-basis: 8.333333%;
}
.col-xs-2 {
  flex-basis: 16.666667%;
}
.col-xs-3 {
  flex-basis: 25%;
}
.col-xs-4 {
  flex-basis: 33.333333%;
}
.col-xs-5 {
  flex-basis: 41.666667%;
}
.col-xs-6 {
  flex-basis: 50%;
}
.col-xs-7 {
  flex-basis: 58.333333%;
}
.col-xs-8 {
  flex-basis: 66.666667%;
}
.col-xs-9 {
  flex-basis: 75%;
}
.col-xs-10 {
  flex-basis: 83.333333%;
}
.col-xs-11 {
  flex-basis: 91.666667%;
}
.col-xs-12 {
  flex-basis: 100%;
}

@media (min-width: 992px) {
  .col-lg-1 {
    flex-basis: 8.333333%;
  }
  .col-lg-2 {
    flex-basis: 16.666667%;
  }
  .col-lg-3 {
    flex-basis: 25%;
  }
  .col-lg-4 {
    flex-basis: 33.333333%;
  }
  .col-lg-5 {
    flex-basis: 41.666667%;
  }
  .col-lg-6 {
    flex-basis: 50%;
  }
  .col-lg-7 {
    flex-basis: 58.333333%;
  }
  .col-lg-8 {
    flex-basis: 66.666667%;
  }
  .col-lg-9 {
    flex-basis: 75%;
  }
  .col-lg-10 {
    flex-basis: 83.333333%;
  }
  .col-lg-11 {
    flex-basis: 91.666667%;
  }
  .col-lg-12 {
    flex-basis: 100%;
  }
}

img,
article img.in-text {
  max-width: 100%;
}

//////////Fonts//////////
h1,
h1 span {
  color: #FFF;
  font-family: $font-2;
  font-size: 5rem;
  font-weight: 400;
  text-align: center;
}
h1 {
  margin: 0 0 30px 0;
  text-align: center;
}
h1 span {
  display: block;
  font-size: 5.4rem;
  text-align: center;
}

h2 {
  font-family: $font-2;
	font-size: 4rem;
  font-weight: 400;
	text-align: center;
  margin: 0 0 60px 0;
}
@media (min-width: 992px) {
  h2 {
  	font-size: 5rem;
  }
}

p {
  font-size: 2rem;
  line-height: 1.75;
  max-width: 700px;
  margin: 0 auto 10px auto;
}

sup {
  top: -0.3em;
}

.center {
  text-align: center;
}

@media (min-width: 992px) {
  .left-lg {
    text-align: left;
  }
}

input[type="submit"],
button,
a.btn {
  @extend %animation;
  background-color: $fz_color-3;
  border: 1px solid transparent;
  border-radius: 30px;
  color: #FFF;
  font-size: 1.8rem;
  display: inline-block;
  padding: 10px 30px;
  text-decoration: none;
  &:hover {
    background: transparent;
    border-color: $fz_color-3;
    color: $fz_color-3;
    cursor: pointer;
  }
}

//////////Header//////////
header {
  .container {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
  }
}
@media (min-width: 992px) {
  header {
    margin-top: 30px;
    .container {
      padding-top: 120px;
    }
  }
}
//////////Homepage//////////
body#homepage header {
  .container {
    flex-direction: column;
    h1 span + span {
      font-size: 3rem;
    }
    img {
      display: block;
      margin: 0 auto;
    }
    a.btn {
      background-color: transparent;
      border-color: #FFF;
    }
  }
}
@media (min-width: 992px) {
  body#homepage header {
    .container {
      flex-direction: row;
      padding-bottom: 30px;
      padding-top: 90px;
      h1 {
        text-align: left;
      }
      h1 span + span {
        text-align: left;
      }
      img {
        margin: 0 30px 0 0;
        max-width: 33%;
      }
      a.btn {
        &:hover {
          background-color: #FFF;
          color: $fz_color-3;
        }
      }
    }
  }
}
//animate_background_1//
header > div {
  background: url(../images/site/header_polygon.svg) no-repeat;
  animation: animate_background_1 100s linear 0s infinite;
  height: 100%;
}
@keyframes animate_background_1 {
  0% {
      background-position: left top;
  }
  25% {
      background-position: bottom -200px center;
  }
  50% {
      background-position: right -200px top -200px;
  }
  75% {
      background-position: bottom -200px bottom;
  }
  100% {
      background-position: left top;
  }
}
//animate_background_2//
header > div > div {
  background: url(../images/site/header_polygon.svg) no-repeat;
  background-size: 186px 216px;
  animation: animate_background_2 30s linear 0s infinite;
  height: 100%;
}
@keyframes animate_background_2 {
  0% {
      background-position: left -200px top -200px;
  }
  25% {
    background-position: bottom center;
  }
  50% {
      background-position: right -200px top -100px;
  }
  75% {
      background-position: bottom center;
  }
  100% {
      background-position: left -200px top -200px;
  }
}
//animate_background_3//
header > div > div > div {
  background: url(../images/site/header_polygon.svg) no-repeat;
  background-size: 560px 648px;
  animation: animate_background_3 40s linear 0s infinite;
  height: 100%;
}
@keyframes animate_background_3 {
  0% {
      background-position: right top;
  }
  25% {
      background-position: bottom -200px center;
  }
  50% {
      background-position: left -200px top -200px;
  }
  75% {
      background-position: bottom -200px center;
  }
  100% {
      background-position: right top;
  }
}
//animate_background_4//
header > div > div > div > div {
  background: url(../images/site/header_polygon.svg) no-repeat;
  background-size: 280px 324px;
  animation: animate_background_4 80s linear 0s infinite;
  height: 100%;
}
@keyframes animate_background_4 {
  0% {
      background-position: right -200px bottom -200px;
  }
  25% {
      background-position: top -200px center;
  }
  50% {
      background-position: left -200px bottom -200px;
  }
  75% {
      background-position: top -200px center;
  }
  100% {
      background-position: right -200px bottom -200px;;
  }
}

//////////Navs//////////
div#supnav {
  background-color: #333;
  height: 30px;
  .container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-end;

    a {
      @extend %animation;
      &:before {
        content: '\203A\00a0';
      }
      border-bottom: 1px solid rgba(255, 255, 255, 0);
      color: #FFF;
      font-size: 1.2rem;
      text-decoration: none;
      &:hover {
        border-color: rgba(255, 255, 255, .6);
      }
    }
    span {
      color: #FFF;
      font-size: 1.2rem;
      a[href="?dcnx"] {
        &:before {
          content: '';
        }
      }
    }
  }
}
@media (min-width: 992px) {
  div#supnav {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;
  }
}

nav {
  background-color: $fz_color-3;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    a {
      color: #FFF;
      display: none;
      font-size: 1.5rem;
      text-decoration: none;
    }
    a.show {
      display: block;
      padding: 15px;
    }
  }
  .center {
    padding: 10px 0;
    a#nav-button {
    }
  }

}
@media (min-width: 992px) {
  nav {
    background-color: rgba(255, 255, 255, .1);
    height: 70px;
    top: 30px;
    .container {
      flex-direction: row;
      a:first-child {
        background: url('..//images/site/logo_fz.svg') no-repeat center center;
        background-size: 47px 54px;
        text-indent: -3000px;
        height: 54px;
        width: 47px;
      }
      a {
        /*@extend %animation;*/
        display: block;
        font-size: 2rem;
        &:hover {
          color: rgba(255, 255, 255, 1);
        }
      }
      a.active {
        color: rgba(255, 255, 255, 1);
      }
    }
    a#nav-button {
      display: none;
    }
  }
  nav.dark-nav {
    background-color: $fz_color-3;
  }
}

//////////Footer//////////
footer {
  border-top: 1px solid #E5E5E5;
  padding-bottom: 70px;
  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    justify-content: space-between;
    padding: 20px;
    > span {
      margin-bottom: 20px;
    }
    > div {
      align-items: center;
      display: flex;
      a {
        margin-left: 20px;
      }
      a[href^="tel:"] {
        background: url('../images/site/icon_phone.svg') no-repeat left center;
        color: $text_color;
        padding: 10px 0 10px 20px;
        text-decoration: none;
      }
      #icon_linkedin {
        @extend %animation;
        polygon {
          fill: $fz_color-3;
        }
        #icon_linkedin_logo {
          fill: #FFF;
        }
        &:hover {
          polygon {
            stroke: $fz_color-3;
            stroke-width: 1px;
            fill: #FFF;
          }
          #icon_linkedin_logo {
            fill: $fz_color-3;
          }
        }
      }
      #icon_twitter {
        @extend %animation;
        polygon {
          fill: $fz_color-3;
        }
        #icon_ltwitter_logo {
          fill: #FFF;
        }
        &:hover {
          polygon {
            stroke: $fz_color-3;
            stroke-width: 1px;
            fill: #FFF;
          }
          #icon_twitter_logo {
            fill: $fz_color-3;
          }
        }
      }
    }
  }
  div + div {
    background-color: $bg_color-2;
    padding: 20px 0;
    p {
      font-size: 1.2rem;
      margin-bottom: 0;
      text-align: center;
    }
  }
}

@media (min-width: 992px) {
  footer {
    padding-bottom: 0;
    .container {
      flex-direction: row;
      > span {
        margin-bottom: 0;
      }
    }
  }
}

//////////Theme Frederic Zugaj//////////
body.frederic-zugaj header {
	background: linear-gradient(117deg, $fz_color-1, $fz_color-2);
}




//////////Sections//////////
section:nth-child(even) {
  background-color: #FFF;
}
section:nth-child(odd){
  background-color: $bg_color-2;
}

article + section:nth-child(even) {
  background-color: $bg_color-2;
}

section#studies,
section#design-process,
section#references,
section#recommandations,
section#faq {
  padding: 120px 0;
}

section#studies {
  .row {
    margin-bottom: 40px;
  }
  a {
    text-decoration: none;
  }
}
.study {
  @extend %clearfix;
  background-color: #FFF;
  border: 1px solid #E5E5E5;
  margin-bottom: 20px;
  width: 100%;
  img {
    display: none;
  }
  div.study-info {
    padding: 20px;
    h3 {
      color: #000;
      font-size: 2rem;
      margin: 0 0 8px 0;
    }
    h4 {
      color: #000;
      font-size: 1.8rem;
      font-weight: 300;
      margin: 0 0 7px 0;
    }
    span.date {
      color: #8F8F8F;
      display: block;
      font-size: 1.2rem;
      margin: 0 0 30px 0;
    }
    span.keyword-label {
      color: #000;
      display: block;
    }
    span.keyword {
      background-color: #EDEDED;
      color: #000;
      font-size: 1.2rem;
      line-height: 3rem;
      margin-right: 10px;
      padding: 5px;
    }
  }
}
@media (min-width: 992px) {
  .study {
    img {
      display: block;
      float: left;
    }
  }
  div.study-info {
    float: left;
    width: calc(100% - 200px);
  }
}

section#design-process {
  p, img {
    margin-bottom: 60px;
  }
}

section#references {
  .row {
    margin-bottom: 50px;
    img {
      @extend %animation;
      filter: grayscale(100%);
      margin-bottom: 20px;
      &:hover {
        filter: grayscale(0);
      }
    }
  }
}

section#recommandations {
  #recommandations-carousel {
    margin: 0 auto;
    max-width: 700px;
    position: relative;
    #slides {
      overflow: hidden;
      position: relative;
      width: 100%;
      ul {
        list-style: none;
        width:100%;
        margin: 0;
        padding: 0;
        position: relative;
        li {
          width:100%;
          float:left;
          position: relative;
        }
      }
    }
    #buttons {
      margin-top: 20px;
      a {
        color: #444;
        font-size: 1.6rem;
        margin-top: 20px;
        padding: 10px;
        text-decoration: none;
        &:hover {
          color: #000;
        }
      }
    }
  }
}
.recommandation {
  .author {
    img {
      display: block;
      margin: 0 auto 10px auto;
    }
    p {
      font-size: 1.2rem;
      text-align: center;
    }
  }
  .quote {
    font-size: 1.8rem;
    font-style: italic;
    padding: 0 50px;
    text-align: center;
  }
}

section#faq {
  p {
    margin-bottom: 60px;
  }
}


//////////Article//////////
#resume {
  background-color: $bg_color-2;
  padding: 50px 0 30px 0;
  .container {
    max-width: 980px;
    .row > div {
      border-left: 1px solid #D8D8D8;
      margin-bottom: 20px;
      .resume-label {
        display: block;
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
      .resume-text {
        font-size: 1.6rem;
        color: #848484;
        span.keyword {
          background-color: #FFF;
          color: #000;
          font-size: 1.2rem;
          line-height: 3rem;
          margin-right: 10px;
          padding: 5px;
        }
      }
    }
  }
}

article {
  padding: 120px 0;
  a {
    color: inherit;
    text-decoration: none;
    border-bottom: 2px dashed #CCC;
    &:hover {
      border-bottom: 2px solid #CCC;
    }
  }
  h3 {
    font-size: 2.4rem;
    line-height: 1.75;
    max-width: 700px;
    margin: 0 auto 10px auto;
  }
  h4 {
    font-size: 1.8rem;
    line-height: 1.75;
    max-width: 700px;
    margin: 0 auto 10px auto;
  }
  ul {
    margin-bottom: 10px;
    li {
      font-size: 2rem;
      line-height: 1.75;
      max-width: 700px;
      margin: 0 auto;
    }
  }
  p + h2,
  .row + h2,
  img + h2,
  ul + h2 {
    margin-top: 100px;
  }
  .in-text {
    margin: 0 auto;
    max-width: 700px;
  }
  .typography {
    img {
      margin: 10px 0 20px 0;
    }
  }
  img,
  img.in-text {
    display: block;
    margin: 20px auto 40px auto;
  }
  img.bordered {
    border: 20px solid #EDEDED;
  }
  img.browser {
    background-color: #F2F2F2;
    background-image: url('../images/site/browser.png');
    background-position: left top;
    background-repeat: no-repeat;
    border: 1px solid #F2F2F2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: 24px;
  }

  .colors {
    max-width: 700px;
    margin: 20px auto;
    .row {
      margin-right: -25px;
      margin-left: -25px;
      > div {
       padding: 0 25px;
       .bordered {
         border: 1px solid #979797;
       }
       span {
         color: #444;
         display: block;
         font-size: 2rem;
         padding: 40px;
         text-align: center;
       }
     }
    }
  }
  span.keyword {
    background-color: #EDEDED;
    color: #000;
    line-height: 4rem;
    margin-right: 10px;
    padding: 5px;
  }
  #kpi-row {
    margin: 0 auto;
    max-width: 700px;
    span.kpi {
      color: #afafaf;
      display: block;
      font-size: 9rem;
      font-weight: 700;
      text-align: center;
    }
    span.kpi ~ p {
      font-size: 1.6rem;
      text-align: center;
    }
  }
  .career {
    margin: 0 auto 40px auto;
    max-width: 700px;
    img {
      display: block;
      margin: 0 auto;
    }
    h4 {
      margin-bottom: 0;
    }
    span.business,
    span.customers {
      display: block;
      font-size: 1.8rem;
      line-height: 3rem;
    }
    span.date {
      color: #B3B3B3;
      display: block;
      font-size: 1.4rem;
      line-height: 3rem;
    }
  }
  #presta-more {
    background-color: $bg_color-2;
    margin-top: 40px;
    padding: 50px;
    h3 {
      text-align: center;
      margin-bottom: 50px;
    }
    span.keyword {
      background-color: #FFF;
    }
  }
  ul#userflows {
    background-color: $bg_color-2;
    margin-bottom: 20px;
    padding: 50px;
    li {
      margin-bottom: 20px;
      max-width: 100%;
    }
  }
}

//////////Theme Frederic Zugaj//////////
body.frederic-zugaj article {
  .colors {
	  .row > div {
		  padding: 0 5px;
	  }
    .color-1 {
      background-color: #085078;
      color: #FFF;
    }
    .color-2 {
      background-color: #85D8CE;
      color: #FFF;
    }
    .color-3 {
      background-color: #333;
      color: #FFF;
    }
    .color-4 {
      background-color: #FFF;
      color: #333;
    }
  }
}

//////////Theme Morgan France//////////
$morgan-france_color-1: #2c7fe1;
$morgan-france_color-2: #cc0404;
$morgan-france_color-3: #cc0404;


body.morgan-france *::selection {
  background: $morgan-france_color-3 none repeat scroll 0 0;
}


body.morgan-france nav.dark-nav {
    background-color: $morgan-france_color-3;
}
@media (max-width: 991px) {
  body.morgan-france nav {
      background-color: $morgan-france_color-3;
  }
}

body.morgan-france header {
	background: linear-gradient(117deg, $morgan-france_color-1, $morgan-france_color-2);
}

body.morgan-france article {
  .colors {
    .color-1 {
      background-color: #002A5C;
      color: #FFF;
    }
    .color-2 {
      background-color: #FFF;
    }
    .color-3 {
      background-color: #990000;
      color: #FFF;
    }
    .color-4 {
      background-color: #FCF8F1;
    }
    .color-5 {
      background-color: #3D4045;
      color: #FFF;
    }
  }
}

//////////Theme McPhy//////////
$mcphy_color-1: #1a5583;
$mcphy_color-2: #004275;
$mcphy_color-3: #004275;


body.mcphy *::selection {
  background: $mcphy_color-3 none repeat scroll 0 0;
}


body.mcphy nav.dark-nav {
    background-color: $mcphy_color-3;
}
@media (max-width: 991px) {
  body.mcphy nav {
      background-color: $mcphy_color-3;
  }
}

body.mcphy header {
	background: linear-gradient(117deg, $mcphy_color-1, $mcphy_color-2);
}

body.mcphy article {
  .colors {
    .color-1 {
      background-color: #004275;
      color: #FFF;
    }
    .color-2 {
      background-color: #FF6666;
      color: #FFF;
    }
    .color-3 {
      background-color: #F5F5F5;
    }
    .color-4 {
      background-color: #2F2F2F;
      color: #FFF;
    }
  }
}

//////////Theme Seen//////////
$seen_color-1: #2a3950;
$seen_color-2: #1a2331;
$seen_color-3: #1a2331;


body.seen *::selection {
  background: $seen_color-3 none repeat scroll 0 0;
}


body.seen nav.dark-nav {
    background-color: $seen_color-3;
}
@media (max-width: 991px) {
  body.seen nav {
      background-color: $seen_color-3;
  }
}

body.seen header {
	background: linear-gradient(117deg, $seen_color-1, $seen_color-2);
}

body.seen article {
  .colors {
    .color-1 {
      background-color: #2E3C54;
      color: #FFF;
    }
    .color-2 {
      background-color: #FFFFFF;
    }
    .color-3 {
      background-color: #E10032;
      color: #FFF;
    }
    .color-4 {
      background-color: #FFAD00;
      color: #FFF;
    }
    .color-5 {
      background-color: #D1D4D9;
    }
  }
}

//////////Theme Appi//////////
$appi_color-1: #df0c36;
$appi_color-2: #b20b37;
$appi_color-3: #b20b37;


body.appi *::selection {
  background: $appi_color-3 none repeat scroll 0 0;
}


body.appi nav.dark-nav {
    background-color: $appi_color-3;
}
@media (max-width: 991px) {
  body.appi nav {
      background-color: $appi_color-3;
  }
}

body.appi header {
	background: linear-gradient(117deg, $appi_color-1, $appi_color-2);
}

//////////Theme Aledia//////////
$aledia_color-1: #48b8eb;
$aledia_color-2: #36a8dc;
$aledia_color-3: #36a8dc;


body.aledia *::selection {
  background: $aledia_color-3 none repeat scroll 0 0;
}


body.aledia nav.dark-nav {
    background-color: $aledia_color-3;
}
@media (max-width: 991px) {
  body.aledia nav {
      background-color: $aledia_color-3;
  }
}

body.aledia header {
	background: linear-gradient(117deg, $aledia_color-1, $aledia_color-2);
}

//////////Theme Exagan//////////
$exagan_color-1: #035898;
$exagan_color-2: #0069b8;
$exagan_color-3: #0069b8;


body.exagan *::selection {
  background: $exagan_color-3 none repeat scroll 0 0;
}


body.exagan nav.dark-nav {
    background-color: $exagan_color-3;
}
@media (max-width: 991px) {
  body.exagan nav {
      background-color: $exagan_color-3;
  }
}

body.exagan header {
	background: linear-gradient(117deg, $exagan_color-1, $exagan_color-2);
}

//////////Theme Urvibes//////////
$urvibes_color-1: #138b89;
$urvibes_color-2: #008280;
$urvibes_color-3: #008280;


body.urvibes *::selection {
  background: $urvibes_color-3 none repeat scroll 0 0;
}


body.urvibes nav.dark-nav {
    background-color: $urvibes_color-3;
}
@media (max-width: 991px) {
  body.urvibes nav {
      background-color: $urvibes_color-3;
  }
}

body.urvibes header {
	background: linear-gradient(117deg, $urvibes_color-1, $urvibes_color-2);
}


//////////Connexion//////////
body#connexion {
  background-color: #85d8ce;
  background: linear-gradient(135deg, $fz_color-1 0%, $fz_color-2 100%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  section {
    background-color: transparent;
    form {
      background-color: #FFF;
      border-radius: 3px;
      margin-bottom: 20px;
      padding: 40px;
      max-width: 350px;
      min-width: 300px;
      width: 90%;
      label {
        font-size: 2rem;
      }
      input {
        border: 1px solid #CCC;
        display: block;
        font-size: 1.4rem;
        margin-bottom: 20px;
        padding: 10px;
        width: 100%;
      }
      p#loginmsg {
        color: red;
        font-size: 1.2rem;
      }
    }
  }
  a {
    color: #FFF;
    font-size: 1.4rem;
    text-decoration: none;
  }
}


//////////contact//////////
body#contact {
  form {
    p.msgok {
      background-color: $fz_color-3;
      color: #FFF;
      padding: 10px;
    }
    p.msgerr {
      background-color: red;
      color: #FFF;
      padding: 10px;
    }
    margin: 0 auto;
    max-width: 700px;
    padding: 50px 0;
    label {
      font-size: 2rem;
    }
    input[type="text"],
    input[type="email"],
    input[type="password"],
    textarea {
      border: 1px solid #CCC;
      display: block;
      font-size: 1.4rem;
      margin-bottom: 20px;
      margin-top: 5px;
      padding: 10px;
      width: 100%;
      &:focus {
        border: 1px solid $fz_color-3;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(90, 170, 177, 0.75);
      }
    }
    textarea {
      height: 300px;
      resize: vertical;
    }
    #icity {
      display: none;
    }
  }
}
